import React, { useEffect } from 'react'
import cns from 'classnames'
import useFormatMessage from '@utils/useFormatMessage'

import styles from './SuccessModal.module.scss'

const SuccessModal = ({ isOpen, onClose }) => {
  const t = useFormatMessage()

  useEffect(() => {
    const toggleBodyScroll = shouldBlockScroll => {
      document.body.style.overflow = shouldBlockScroll ? 'hidden' : ''
    }
    toggleBodyScroll(isOpen)

    return () => {
      toggleBodyScroll(false)
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <div className={styles.layout} onClick={onClose}>
      <div className={styles.modal} onClick={e => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <div className={styles.wrapper}>
          <picture className={styles.image}>
            <source type="image/webp" src="/images/success.webp" />
            <source type="image/png" src="/images/success.png" />
            <img src="/images/success.png" alt={t('successModal.title')} />
          </picture>
          <h1 className={styles.title}>{t(`successModal.title`)}</h1>
          <h3 className={styles.description}>
            {t(`successModal.description`)}
          </h3>
          <button
            className={cns(styles.button, `button`, `button--secondary`)}
            onClick={onClose}
          >
            {t(`successModal.button.done`)}
          </button>
        </div>
      </div>
    </div>
  )
}

export default SuccessModal
