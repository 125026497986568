import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { useSearchParams } from 'next/navigation'
import cns from 'classnames'

import { LANGS_FOR_DEMO } from '@constants/index'

import useLanguageUrl from '@utils/useLanguageUrl'
import $amplitude from '@utils/amplitude'
import useFormatMessage from '@utils/useFormatMessage'
import { useABTest } from '@utils/useABTest'

import LanguageMenu from '@components/layouts/header/languageMenu/languageMenu'
import DemoModal from '@components/demoModal/DemoModal/DemoModal'
import SuccessModal from '@components/demoModal/SuccesModal/SuccessModal'

import styles from './menu.module.scss'

function Menu({ pageId, handleClick, isMobileNavVisible }) {
  const t = useFormatMessage()
  const { push, locale, pathname } = useRouter()
  const searchParams = useSearchParams()
  const langUrl = useLanguageUrl()
  const langUrlSignin = useLanguageUrl('signin')
  const { isABTestForPathname2 } = useABTest()
  const isShowDemo = LANGS_FOR_DEMO.includes(locale)

  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  const handleLoginButtonClick = useCallback(() => {
    $amplitude('[Landing] Login Button Click', {
      page_name: pageId,
      block_name: 'header',
    })
  }, [pageId])

  const handleOpenDemoModal = useCallback(() => {
    setIsDemoModalOpen(true)
    $amplitude('[Landing] Demo Button Click', { page_name: pageId })
    $amplitude('[Landing] Demo Modal Window View')
  }, [pageId])

  const handleCloseDemoModal = useCallback(() => {
    setIsDemoModalOpen(false)
  }, [])

  const handleOpenSuccessModal = useCallback(() => {
    setIsSuccessModalOpen(true)
  }, [])
  const handleCloseSuccessModal = useCallback(
    () => setIsSuccessModalOpen(false),
    []
  )

  const handleRegistrationButtonClick = useCallback(() => {
    $amplitude('[Landing] Registration Button Click', {
      page_name: pageId,
      block_name: 'header',
    })
  }, [pageId])

  useEffect(() => {
    if (isShowDemo) {
      if (searchParams.has('modal', 'demo')) {
        handleOpenDemoModal()
        // очищаем URL
        push(pathname)
      }
    }
  }, [isShowDemo, pathname, searchParams, push, handleOpenDemoModal])

  return (
    <div className={styles.root}>
      {isShowDemo && (
        <div className={cns(styles.item_demo)}>
          <button
            className={cns(styles.link, styles.link_separated)}
            onClick={handleOpenDemoModal}
          >
            {t(`nav.links.demo`)}
          </button>
        </div>
      )}

      <div className={styles.item_signup}>
        <a
          className={cns(styles.link, styles.link_bold)}
          href={langUrlSignin}
          rel="nofollow"
          onClick={handleLoginButtonClick}
        >
          {t(`nav.links.login`)}
        </a>
      </div>

      <div className={cns(styles.item_signup)}>
        <a
          className={cns(
            styles.link,
            styles.link_bold,
            styles.signUp,
            'button',
            'button--small'
          )}
          href={langUrl}
          rel="nofollow"
          onClick={handleRegistrationButtonClick}
        >
          {t(isABTestForPathname2 ? 'try' : 'nav.links.tryFree')}
        </a>
      </div>

      <div className={cns(styles.item_language)}>
        <LanguageMenu />
      </div>

      <div
        className={cns(styles.menu, isMobileNavVisible && styles.menu_opened)}
      >
        <button
          className={styles.menu}
          type="button"
          aria-label="Меню"
          id="mobile-menu"
          onClick={handleClick}
        >
          <i className={styles.menu__ico} />
        </button>
      </div>

      {isDemoModalOpen && (
        <DemoModal
          isOpen={isDemoModalOpen}
          locale={locale}
          handleOpenSuccessModal={handleOpenSuccessModal}
          onClose={handleCloseDemoModal}
        />
      )}
      {isSuccessModalOpen && (
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={handleCloseSuccessModal}
        />
      )}
    </div>
  )
}

Menu.propTypes = {
  pageId: PropTypes.string,
  handleClick: PropTypes.func,
  isMobileNavVisible: PropTypes.bool,
}

export default Menu
